
.session-bg[data-v-4232f3d4] {
    display: flex;
    flex-wrap: wrap;
}
.session-label[data-v-4232f3d4] {
    margin: 3px 5px 3px 0;
    background-color: #ff8143;
    color: white;
    padding: 0 5px;
    border-radius: 5px;
    line-height: 25px;
}
.card-bg[data-v-4232f3d4] {
    padding: 15px;
    display: flex;
    flex-flow: wrap;
}
.card-bg-noData[data-v-4232f3d4] {
    padding: 30px;
    text-align: center;
}
.goods-card[data-v-4232f3d4] {
    border-radius: 4px;
    border: 1px solid #EBEEF5;
    background-color: #FFFFFF;
    overflow: hidden;
    color: #303133;
    transition: 0.3s;
    /* box-sizing: border-box; */


    width: 275px;
    /* height: 160px; */
    margin: 15px 0px 15px 15px;
    /* background: red; */
    display: flex;
    padding: 15px;
    align-items: center;
}
.goods-image[data-v-4232f3d4] {
    width: 90px;
    height: 120px;
    overflow: hidden;
    display: flex;
    align-items: center;
    /* margin: 20px 0px 20px 15px; */
}
.goods-detail[data-v-4232f3d4] {
    /* padding: 20px 15px 20px 15px; */
    margin-left: 15px;
}
.goods-name[data-v-4232f3d4] {
    margin-bottom: 5px;
}
.goods-other[data-v-4232f3d4] {
    margin-bottom: 5px;
}
.text-line-1[data-v-4232f3d4] {
    display: -webkit-box;
    word-break: break-all;
    /*超出文字用省略号代替*/
    text-overflow: ellipsis;
    /*竖直方向的超出和隐藏*/
    -webkit-box-orient: vertical;
    /*设定行数为2*/
    -webkit-line-clamp: 1;
    /*多出部分隐藏*/
    overflow: hidden;
}
.text-line-2[data-v-4232f3d4] {
    display: -webkit-box;
    word-break: break-all;
    /*超出文字用省略号代替*/
    text-overflow: ellipsis;
    /*竖直方向的超出和隐藏*/
    -webkit-box-orient: vertical;
    /*设定行数为2*/
    -webkit-line-clamp: 2;
    /*多出部分隐藏*/
    overflow: hidden;
}
